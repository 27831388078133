/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* ion-content {
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 20px;
  --padding-top: 20px;
} */
boton-ingreso{
	background: red;
	color: var(--color-donativo)
}
ion-slides {
  height: 100%;
}
.ion-color-donativo {
  --ion-color-base: var(--ion-color-donativo);
  --ion-color-base-rgb: var(--ion-color-donativo-rgb);
  --ion-color-contrast: var(--ion-color-donativo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-donativo-contrast-rgb);
  --ion-color-shade: var(--ion-color-donativo-shade);
  --ion-color-tint: var(--ion-color-donativo-tint);
}
.ion-color-egreso {
  --ion-color-base: var(--ion-color-egreso);
  --ion-color-base-rgb: var(--ion-color-egreso-rgb);
  --ion-color-contrast: var(--ion-color-egreso-contrast);
  --ion-color-contrast-rgb: var(--ion-color-egreso-contrast-rgb);
  --ion-color-shade: var(--ion-color-egreso-shade);
  --ion-color-tint: var(--ion-color-egreso-tint);
}
.ion-color-ingreso {
  --ion-color-base: var(--ion-color-ingreso);
  --ion-color-base-rgb: var(--ion-color-ingreso-rgb);
  --ion-color-contrast: var(--ion-color-ingreso-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ingreso-contrast-rgb);
  --ion-color-shade: var(--ion-color-ingreso-shade);
  --ion-color-tint: var(--ion-color-ingreso-tint);
}
/* ion-header {
	margin-top: constant(safe-area-inset-top);
	margin-top: env(safe-area-inset-top);
} */
/** Ionic CSS Variables **/
:root {
	--ion-color-donativo: #FFBD50;
  --ion-color-donativo-rgb: 105, 187, 123;
  --ion-color-donativo-contrast: #0061B0;
  --ion-color-donativo-contrast-rgb: 255, 255, 255;
  --ion-color-donativo-shade: #FFBD50;
  --ion-color-favorite-tint: #FFBD50;

	--ion-color-egreso: #1398E8;
  --ion-color-egreso-rgb: 105, 187, 123;
  --ion-color-egreso-contrast: #EFDCA9;
  --ion-color-egreso-contrast-rgb: 255, 255, 255;
  --ion-color-egreso-shade: #1398E8;
  --ion-color-egreso-tint: #1398E8;

	--ion-color-ingreso: #005EC0;
  --ion-color-ingreso-rgb: 0, 94, 192;
  --ion-color-ingreso-contrast: #EFDCA9;
  --ion-color-ingreso-contrast-rgb: 255, 255, 255;
  --ion-color-ingreso-shade: #005EC0;
  --ion-color-ingreso-tint: #005EC0;


	--ion-color-whatsapp: #1BC215;
  --ion-color-whatsapp-rgb: 56, 128, 255;
  --ion-color-whatsapp-contrast: #ffffff;
  --ion-color-whatsapp-contrast-rgb: 255, 255, 255;
  --ion-color-whatsapp-shade: #1BC215;
  --ion-color-whatsapp-tint: #1BC215;

  /** primary **/
  --ion-color-primary: #005EC0;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #FFE2A3;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #005EC0;
  --ion-color-primary-tint: #005EC0;

  /** secondary **/
  --ion-color-secondary: #FFE2A3;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

